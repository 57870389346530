import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import barsImg from "../assets/bars.png";
const Header = () => {
  const [bars, setBars] = useState(false);

  window.addEventListener("resize", () => {
    window.innerWidth < 550 ? setBars(true) : setBars(false);
  });

  const handleBarsClick = () => {
    if (document.getElementById("barsMenu").style.display === "block") {
      document.getElementById("barsMenu").style.display = "none";
    } else {
      document.getElementById("barsMenu").style.display = "block";
    }
  };

  return (
    <nav>
      <h1>React Gallery</h1>
      {bars === false ? (
        <main>
          <HashLink to="/#home">Home</HashLink>
          <Link to="/contact">Contact</Link>
          <HashLink to="/#about">About</HashLink>
          <HashLink to="/#brands">Brands</HashLink>
          <Link to="/services">Services</Link>
        </main>
      ) : (
        <div>
          <img
            src={barsImg}
            alt=""
            style={{
              backgroundColor: "black",
              width: "50px",
              height: "30px",
              padding: "3px 0px",
            }}
            onClick={handleBarsClick}
          />
        </div>
      )}
      <div className="barsMenu" id="barsMenu">
        <div>
          <HashLink to="/#home">Home</HashLink>
          <Link to="/contact">Contact</Link>
          <HashLink to="/#about">About</HashLink>
          <HashLink to="/#brands">Brands</HashLink>
          <Link to="/services">Services</Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;
