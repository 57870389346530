import React from "react";
import vg from "../assets/2.webp";
import {
  AiFillGoogleCircle,
  AiFillAmazonCircle,
  AiFillYoutube,
  AiFillInstagram,
} from "react-icons/ai";

const Home = () => {
  return (
    <>
      <div className="home" id="home">
        <main>
          <h1>React Gallery</h1>
          <p>Solution to all your React Problems</p>
        </main>
      </div>

      <div className="home2">
        <img src={vg} alt="Graphics" />
        <div>
          <p>
            We are your best solution to the Web development and Tech Problems
            you face daily. We are here to make your day!
          </p>
        </div>
      </div>

      <div className="home3" id="about">
        <div>
          <h1>Who we are ?</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque
            pariatur aliquam ut laudantium amet quibusdam, inventore odit,
            repellat est voluptates possimus officiis recusandae, voluptas
            neque? Excepturi perspiciatis obcaecati, corporis eos quae suscipit
            labore nemo non quas magni ducimus. Harum reprehenderit suscipit
            error pariatur amet. Eaque rerum, quae earum voluptatibus corrupti
            dolor cumque perferendis dolorum laudantium eum fuga alias suscipit
            rem sequi similique consectetur ut placeat reiciendis molestias,
            eius cum voluptatem perspiciatis eos impedit. Magnam soluta itaque
            natus ut provident? Laboriosam excepturi repudiandae nesciunt
            incidunt tempora ipsa? Aperiam corrupti, quos sint enim aspernatur
            velit asperiores ab neque suscipit provident atque magni molestiae
            repellat a assumenda culpa natus maiores sit quasi in accusantium
            facere possimus. Dolor, quod saepe ea consequatur quidem suscipit
            harum? Quasi aut alias deserunt molestias omnis repellat veniam eius
            accusamus adipisci blanditiis! Mollitia, eveniet libero assumenda
            esse eius autem possimus nostrum sed maxime accusantium id veritatis
            aliquam soluta cum ex. Sit dignissimos sapiente molestiae molestias
            consequatur numquam perferendis quas, suscipit eius iure error quod
            et adipisci esse quidem fugit itaque nostrum. Sed itaque qui ipsum
            magni? at beatae tenetur praesentium
          </p>
        </div>
      </div>

      <div className="home4" id="brands">
        <div>
          <h1>Brands</h1>
          <article>
            <div style={{ animationDelay: "0.3s" }}>
              <AiFillGoogleCircle />
              <p>Google</p>
            </div>

            <div style={{ animationDelay: "0.5s" }}>
              <AiFillAmazonCircle />
              <p>Amazon</p>
            </div>

            <div style={{ animationDelay: "0.7s" }}>
              <AiFillYoutube />
              <p>Youtube</p>
            </div>

            <div style={{ animationDelay: "0.9s" }}>
              <AiFillInstagram />
              <p>Instagram</p>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default Home;
