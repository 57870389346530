import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import img1 from "../assets/3.jpg";
import img2 from "../assets/4.jpg";

const Services = () => {
  return (
    <Carousel
      infiniteLoop
      autoPlay
      showStatus={false}
      showThumbs={false}
      interval={1000}
    >
      <div>
        <img src={img1} alt="image1" />
        <p className="legend">Front End Development</p>
      </div>

      <div>
        <img src={img2} alt="image2" />
        <p className="legend">Back End Development</p>
      </div>
    </Carousel>
  );
};

export default Services;
