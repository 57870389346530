import React, { useState } from "react";

const Contact = () => {
  const [userDetails, setUserDetails] = useState({
    username: "",
    email: "",
    message: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userDetails);
    setUserDetails({
      username: "",
      email: "",
      message: "",
    });
  };

  return (
    <div className="contact">
      <main>
        <h1>Contact Us</h1>

        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Username </label>
          <input
            type="text"
            name="username"
            id="username"
            placeholder="Enter your name."
            value={userDetails.username}
            onChange={handleChange}
          />

          <label htmlFor="email">Email </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email."
            value={userDetails.email}
            onChange={handleChange}
          />

          <label htmlFor="message">Message </label>
          <input
            type="text"
            name="message"
            id="message"
            placeholder="Enter your query ..."
            value={userDetails.message}
            onChange={handleChange}
          />

          <button type="submit">Submit</button>
        </form>
      </main>
    </div>
  );
};

export default Contact;
