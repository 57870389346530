import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <h1>React Gallery</h1>
        <p>Your best Partner!</p>
      </div>

      <div id="links">
        <h4>Follow Us</h4>
        <a href="https://youtube.com">Youtube</a>
        <a href="https://instagram.com">Instagram</a>
        <a href="https://facebook.com">Facebook</a>
      </div>
    </div>
  );
};

export default Footer;
